<template>
<div class="home-interior-office-wrapper w-full flex items-center justify-center">
  <div class="container">
    <div class="home-interior-header flex flex-row items-center justify-between">
      <h3>인테리어 <b>업체</b></h3>

      <div class="interior-office-more flex flex-row justify-end">
        <router-link :to="{name: 'InteriorList'}">업체 더보기 +</router-link>
      </div>
    </div>

    <div class="home-interior-items grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-2 gap-8">
      <InteriorOfficeItem v-for="item in interiors" :key="item.id" :data="item" />
    </div>
  </div>
</div>
</template>

<script>
import InteriorOfficeItem from '@/components/InteriorOfficeItem.vue';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'HomeInteriorOfficeList',
  components: { InteriorOfficeItem },
  props: {
    data: Array
  },
  mounted() {
    this.getInteriorOfficeList();
  },
  computed: {
    ...mapState('interior', ['interiors']),
  },
  methods: {
    ...mapActions('interior', ["getInteriorOfficeList"]),
  },
}
</script>

<style scoped lang="scss">
.home-interior-office-wrapper {
  padding-top: 65px;
  padding-bottom: 75px;
  background-color: #f5f5f5;

  h3 {
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 56px;

    b {
      font-weight: 500;
    }
  }

  .interior-office-more {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: center;
    color: #808080;
    margin-bottom: 40px;
  }
}

@media (max-width: 639px) {
  .home-interior-office-wrapper {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .interior-office-more {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
</style>