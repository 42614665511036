<template>
  <div
    v-if="userType === 'normal'"
    class="info-wrapper flex flex-row items-center"
    :class="{ 'map-item': isMap }"
  >
    <div class="flex flex-row items-center favorite-wrapper">
      <div
        class="flex items-center justify-center favorite"
        @click.stop="onFavorite"
      >
        <eva-icon
          :name="isFavorite ? 'star' : 'star-outline'"
          :width="size"
          :height="size"
          :fill="isFavorite ? '#293bf0' : '#616161'"
        />
      </div>
      <span>{{ subscribe.length }}</span>
    </div>

    <div class="flex flex-row items-center room">
      <eva-icon
        name="message-circle-outline"
        :width="size - 2"
        :height="size - 2"
        :fill="'#616161'"
      />
      <span>{{ roomCount }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import services from "@/services";

export default {
  name: "PlaceInfo",
  props: {
    roomCount: Number,
    id: Number,
    subscribe: Array,
    size: {
      type: Number,
      default: 22,
    },
    isMap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  mounted() {
    this.isFavorite =
      this.subscribe.filter((s) => s.userId === this.userId).length > 0;
  },
  methods: {
    async onFavorite() {
      if (!this.isLoggedIn) {
        return;
      }

      if (this.isFavorite) {
        await services.placeRemoveSubscribe(this.id);
        this.isFavorite = false;
        this.$toast.open("찜해제 되었습니다");
      } else {
        await services.placeAddSubscribe(this.userId, this.id);
        this.isFavorite = true;
        this.$toast.open("찜이 되었습니다");
      }
    },
  },
  computed: {
    ...mapState("user", ["userId", "userType", "isLoggedIn"]),
  },
};
</script>

<style scoped lang="scss">
.info-wrapper {
  .favorite-wrapper {
    margin-right: 14px;
  }

  .favorite {
    margin-right: 8px;
  }

  .room i {
    margin-right: 8px;
  }

  span {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.6px;
    text-align: left;
    color: #616161;
  }
}

@media (max-width: 1023px) {
  .info-wrapper {
    &.map-item {
      .favorite {
        margin-right: 5px;
      }
      .room {
        i {
          margin-right: 5px;
        }
      }
      span {
        font-size: 11px;
      }
    }
  }
}
</style>
