<template>
  <div class="header-wrapper relative w-full flex items-center justify-center">
    <div class="header-inner flex flex-col items-center justify-end">
      <div class="header container flex flex-col relative">
        <h3>낡고 오래된 빈집</h3>
        <h1>놀리면</h1>
        <h1 class="jumbotron-title-2">뭐하니</h1>

        <div class="input-wrapper relative">
          <button class="input-icon" @click="onSearch">
            <img src="@/assets/search.png" />
          </button>

          <input
            v-model="query"
            class="w-full h-full"
            placeholder="주소를 입력하세요"
            @keyup.enter="onSearch"
          />
        </div>

        <img class="header-image-1" src="@/assets/home-1.png" />
        <img class="header-image-2" src="@/assets/home-2.png" />

        <div class="header-image-3">
          <img src="@/assets/home-3.png" />
          <p>여기다.<br />내가 찾던,<br />감성 창업 공간</p>
        </div>

        <div class="header-image-4">
          <img src="@/assets/home-4.png" />
          <p>놀리면 뭐혀~<br />골치만 아프지</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Jumbotron1",
  data() {
    return {
      query: "",
    };
  },
  methods: {
    onSearch() {
      this.$router.push({ name: "PlaceList", query: { query: this.query } });
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrapper {
  height: 622px;
  background-color: #283bf1;

  .header-inner {
    z-index: 2;
    padding-top: 100px;
    margin-bottom: -67px;
  }
}

.header-image-1 {
  position: absolute;
  width: 70px;
  top: 75px;
  right: -90px;
  z-index: 1;
}

.header-image-2 {
  position: absolute;
  width: 120px;
  bottom: -16px;
  right: -150px;
  z-index: 1;
}

.header-image-3 {
  position: absolute;
  width: 100px;
  top: -30px;
  right: -60px;
  z-index: 1;

  p {
    position: absolute;
    top: 14px;
    left: 16px;
    text-align: left !important;
    font-size: 11px !important;
    color: #222 !important;
  }
}

.header-image-4 {
  position: absolute;
  width: 100px;
  top: 60px;
  right: -200px;
  z-index: 1;

  p {
    position: absolute;
    top: 25px;
    left: 16px;
    text-align: left !important;
    font-size: 11px !important;
    color: #222 !important;
  }
}

.header {
  h3 {
    font-family: "BM Hanna 11yrs old";
    font-size: 34px;
    color: white;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.6);
  }

  h1 {
    font-family: "BM Hanna 11yrs old";
    font-size: 124px;
    line-height: 124px;
    margin: 0;
    color: white;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.6);
  }

  p {
    color: white;
    font-size: 24px;
    text-align: center;
    font-weight: 400;

    b {
      font-weight: 500;
    }
  }

  .input-wrapper {
    width: 320px;
    height: 42px;
    margin-top: 30px;
    margin-bottom: 50px;
    background-color: #fff;
    position: relative;

    .input-icon {
      position: absolute;
      left: 0;
      height: 42px;
      width: 42px;
      background-color: #283bf1;
      border: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }
    }

    input {
      background-color: transparent;
      font-size: 14px;
      letter-spacing: -0.72px;
      text-align: center;
      color: #283bf1;
      font-weight: 500;
      padding-left: 45px;

      &::placeholder {
        color: #283bf1;
      }
    }

    .input-search {
      top: 15px;
      right: 20px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.jumbotron-title-2 {
  margin-left: 10px !important;
}

@media (max-width: 767px) {
  .header-wrapper {
    overflow: hidden;
    height: 500px;
    h3 {
      font-size: 20px;
    }
    h1 {
      font-size: 80px;
      line-height: 1.2;
    }
    .header-inner {
      padding-top: 70px;
    }
    .input-wrapper {
      width: 100%;
      height: 35px;
      .input-icon {
        width: 35px;
        height: 100%;
        &>img {
          width: 15px;
          height: 100%;
          object-fit: contain;
        }
      }
      input {
        font-size: 12px;
      }
    }
    .header-image-1 {
      width: 42px;
      top: 75px;
      right: -90px;
    }
    .header-image-2 {
      width: 72px;
      bottom: 43px;
      right: -131px;
    }
    .header-image-3 {
      width: 91px;
      top: -21px;
      right: -78px;
      p {
        font-size: 10px !important;
      }
    }
    .header-image-4 {
      width: 83px;
      top: 58px;
      right: -187px;
      p {
        top: 19px;
        left: 13px;
        font-size: 10px !important;
      }
    }
  }
}
@media (max-width: 589px) {
  .header-wrapper {
    .header-image-1,
    .header-image-2,
    .header-image-3,
    .header-image-4 {
      display: none;
    }
  }
}
</style>
