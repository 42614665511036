<template>
  <div class="w-full">
    <Jumbotron1 />
    <HomePlaceList />
    <HomeInteriorReviewList />
    <HomeInteriorOfficeList />

    <div class="section-2 w-full flex flex-col items-center">
      <div
        class="section-2-header container flex flex-row items-center justify-between"
      >
        <h3><b>홍보영상</b></h3>
      </div>

      <div class="section-2-slider w-full">
        <vueper-slides
          class="no-shadow"
          :bullets="false"
          :touchable="false"
          :autoplay="true"
          fixedHeight="480px"
        >
          <vueper-slide
            v-for="(slide, i) in marketings"
            :key="i"
            :image="slide.image"
            :title="slide.title"
            :content="slide.content"
            :video="slide.video"
          />
        </vueper-slides>
      </div>
    </div>
  </div>
</template>

<script>
import HomePlaceList from "@/components/HomePlaceList.vue";
import HomeInteriorReviewList from "@/components/HomeInteriorReviewList.vue";
import HomeInteriorOfficeList from "@/components/HomeInteriorOfficeList.vue";
import Jumbotron1 from "@/components/Jumbotron1.vue";
import services from "@/services";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import utils from "@/utils";

export default {
  name: "Home",
  components: {
    HomePlaceList,
    HomeInteriorOfficeList,
    HomeInteriorReviewList,
    VueperSlides,
    VueperSlide,
    Jumbotron1,
  },
  data() {
    return {
      marketings: [],
    };
  },
  mounted() {
    this.getMaretking();
  },
  methods: {
    async getMaretking() {
      const data = await services.getMarketing();
      console.log(data);
      this.marketings = data.map((d) => {
        return {
          video: {
            mp4: utils.getImageUrl(d),
            props: { autoplay: true, loop: true, controls: false, muted: true },
          },
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.section-1 {
  padding-top: 80px;

  .section-1-title-1 {
    font-weight: 300;
    color: #1a1a1a;
    letter-spacing: -0.22px;
    font-size: 22px;
    margin-bottom: 25px;
  }

  .section-1-title-2 {
    font-weight: 700;
    color: #283cf0;
    letter-spacing: -1.52px;
    font-size: 38px;
    margin-bottom: 90px;
  }

  .section-1-content {
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #1a1a1a;
    font-weight: 300;
    margin-bottom: 65px;
    margin-top: 105px;

    b {
      font-weight: 500;
    }
  }

  .section-1-content-1 {
    margin-bottom: 120px;
  }

  .section-1-items {
    img {
      width: 100%;
      height: 10px;
      object-fit: cover;
      z-index: 0;
    }

    .woman {
      width: 148px;
      height: 338px;
      z-index: 2;
      left: 230px;
      bottom: -180px;
    }

    .man {
      width: 190px;
      height: 330px;
      z-index: 2;
      right: 220px;
      bottom: -185px;
    }

    .section-1-item {
      width: 294px;
      height: 294px;
      border-radius: 50%;
      border: 10px solid #6472f4;
      background-color: white;
      z-index: 1;

      p {
        font-size: 17px;
        text-align: center;
        letter-spacing: -0.17px;
        color: #2b2b2b;
      }

      h5 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -1.5px;
        color: #6472f4;
        margin-top: 30px;
        margin-bottom: 50px;
      }

      &:first-child {
        margin-right: 105px;
      }

      &:last-child {
        margin-left: 105px;
      }
    }
  }
}

.section-2 {
  padding-top: 65px;
  padding-bottom: 75px;
  background-color: #282828;

  h3 {
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    text-align: left;
    color: #fff;
    margin-bottom: 56px;

    b {
      font-weight: 500;
    }
  }

  .press-more {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
  }
}

@media (max-width: 1023px) {
  .section-2 {
    h3 {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 639px) {
  .section-2 {
    padding: 50px 20px;
    h3 {
      font-size: 20px;
    }
    .section-2-slider {
      width: calc(100% - 60px);
      padding-bottom: 55%;
      position: relative;
      margin: 0 auto;
      .vueperslides {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        .vueperslides__track-inner {
          .vueperslide {
            height: 100% !important;
            video {
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}
</style>
