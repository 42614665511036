<template>
  <div class="stack-wrapper w-full h-full">
    <stack
      :column-min-width="400"
      :gutter-width="15"
      :gutter-height="15"
      monitor-images-loaded
    >
      <stack-item
        v-for="(item, index) in images"
        :key="index"
        style="transition: transform 300ms"
      >
        <img
          @click="() => goToDetail(item)"
          class="stack-item-image w-full h-full"
          :src="item.image"
        />
      </stack-item>
    </stack>
  </div>
</template>

<script>
import { Stack, StackItem } from "vue-stack-grid";
import utils from "@/utils";

export default {
  name: "DaynamicStack",
  components: {
    Stack,
    StackItem,
  },
  props: {
    data: Array,
  },
  methods: {
    goToDetail(item) {
      this.$router.push({
        name: "InteriorReviewDetail",
        params: { id: item.id },
      });
    },
  },
  computed: {
    images() {
      return this.data
        .map((d) => {
          const { hasTradeReviewFiles } = d;
          if (hasTradeReviewFiles.length > 0) {
            return {
              id: d.id,
              image: utils.getImageUrl(hasTradeReviewFiles[0]),
            };
          }
          return { id: null, image: null };
        })
        .filter((d) => !!d);
    },
  },
};
</script>

<style scoped lang="scss">
.stack-wrapper {
  .stack-item-image {
    border-radius: 12px;
    margin: 0;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 639px) {
  .stack-wrapper {
    height: auto !important;
    .vsg-container {
      height: auto !important;
      display: flex;
      flex-direction: column;
      .vsg-stack-item {
        position: relative;
        top: unset;
        left: unset;
        transform: unset !important;
        margin-bottom: 20px;
      }
    }
    .stack-item-image {
      max-width: 200px;
      margin: 0 auto;
    }
  }
}
</style>
