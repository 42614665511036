<template>
  <div class="place-item-wrapper" @click.stop="goToDetail">
    <div
      class="place-item-image"
      :style="{
        backgroundImage: image ? `url(${image})` : '',
      }"
    />
    <div class="place-item-content">
      <div
        class="place-item-content-header flex flex-row items-center justify-between"
      >
        <PlaceInfo
          :id="data.id"
          :subscribe="data.hasUserSubscribePlaces"
          :roomCount="data.hasChatRooms.length"
        />
      </div>
      <p @click="goToDetail" class="place-item-title">{{ data.title }}</p>
      <p class="place-classification" @click="goToDetail">
        {{ classification }}
      </p>
      <div class="flex flex-row items-center price">
        <span>보증금</span> <span>{{ utilities.toLocaleString() }}만원</span>
      </div>
      <div class="flex flex-row items-center price">
        <span>월</span> <span>{{ leasePrice.toLocaleString() }}만원</span>
      </div>
    </div>
    <div
      class="place-item-button-group flex flex-row items-center justify-between"
    >
      <button
        @click.stop="goToJoin"
        class="flex flex-row items-center justify-center compatition"
      >
        <img src="@/assets/vote.png" alt="vote" />경쟁임차
      </button>
      <button
        @click.stop="goToChat"
        class="flex flex-row items-center justify-center contact"
      >
        <img src="@/assets/chat.png" alt="vote" />상세보기
      </button>
    </div>
  </div>
</template>

<script>
import PlaceInfo from "@/components/PlaceInfo";
import utils from "@/utils";
import { mapState } from "vuex";

export default {
  name: "PlaceItem",
  components: { PlaceInfo },
  props: {
    data: Object,
  },
  methods: {
    goToDetail() {
      this.$router.push({ name: "PlaceDetail", params: { id: this.data.id } });
    },
    goToJoin() {
      this.$router.push({ name: "PlaceOffer", params: { id: this.data.id } });
    },
    goToChat() {
      if (this.userType === "normal") {
        this.$router.push({
          name: "PlaceDetail",
          params: { id: this.data.id },
        });
      } else {
        this.$toast.open({
          message: "상담은 임차인만 가능합니다",
          type: "warning",
        });
      }
    },
  },
  computed: {
    image() {
      const images = utils.getPlaceImages(this.data);
      if (images.length > 0) {
        return images[0];
      }
      return "";
    },
    classification() {
      if (this.data.hasPlaceClassification) {
        const { title } = this.data.hasPlaceClassification;
        return title;
      }

      return "";
    },
    utilities() {
      const { utilities: price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 0;
    },
    leasePrice() {
      const { leasePrice: price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 0;
    },
    ...mapState("user", ["userType"]),
  },
};
</script>

<style scoped lang="scss">
.place-item-wrapper {
  cursor: pointer;

  .place-item-image {
    height: 300px;
    background-color: #ddd;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .place-item-content {
    padding-top: 20px;
    padding-bottom: 18px;

    .place-item-content-tag {
      font-size: 16px;
      font-weight: 500;
      color: #7ca3aa;
      letter-spacing: -0.64px;
    }

    p {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.8px;
      color: #222;
    }

    .place-classification {
      color: #7ca3aa;
      font-size: 16px;
      font-weight: 500;
      margin-top: 5px;
    }

    .price {
      margin-top: 5px;

      span:first-child {
        font-size: 16px;
        letter-spacing: -0.64px;
        color: #616161;
        margin-right: 8px;
      }

      span:last-child {
        font-size: 22px;
        font-weight: 700;
        letter-spacing: -0.96px;
        color: #101010;
      }
    }
  }

  .place-item-button-group {
    button {
      flex: 1;
      height: 50px;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.64px;

      img {
        margin-right: 7px;
        width: 20px;
        height: 20px;
      }

      &:first-child {
        margin-right: 8px;
      }

      &.compatition {
        background-color: #293bf0;
      }

      &.contact {
        background-color: #293bf0;
      }
    }
  }

  .place-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 639px) {
  .place-item-wrapper {
    .place-item-image {
      width: 200px;
      height: 150px;
      margin: 0 auto;
    }
    .place-item-content {
      p {
        font-size: 12px;
      }
      .place-classification {
        font-size: 11px;
      }
      .price {
        span {
          &:first-child,
          &:last-child {
            font-size: 11px;
          }
        }
      }
    }
    .place-item-button-group {
      button {
        height: 40px;
        font-size: 12px;
        img {
          width: 15px;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
