<template>
  <div>
    <slot />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";

export default {
  name: "GeoLocation",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    onLoadStart: {
      type: Function,
      default: function() {},
    },
    onLoadEnd: {
      type: Function,
      default: function() {},
    },
  },
  data() {
    return {
      latitude: null,
      longitude: null,
      name: "",
    };
  },
  mounted() {
    this.currentLocation();
  },
  methods: {
    geoLocation: async function() {
      if ("geolocation" in navigator) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const { coords } = pos;
              if (coords) {
                const { latitude, longitude } = coords;
                resolve({ latitude, longitude });
              }
            },
            (err) => {
              reject(err);
            }
          );
        });
      }
    },
    currentLocation: async function() {
      this.onLoadStart();
      try {
        const { latitude, longitude } = await this.geoLocation();
        this.latitude = latitude;
        this.longitude = longitude;
        this.getAddress();
        this.setLocation({
          latitude: this.latitude,
          longitude: this.longitude,
          name: this.name,
        });
      } catch (e) {
        const { code } = e;
        if (code === 1 && !this.permission) {
          this.$toast.open("위치 권한을 허용해주세요");
          this.setPermission();
          this.resetLocation();
        }
      } finally {
        this.onLoadEnd();
      }
    },
    getAddress: async function() {
      if (this.latitude && this.longitude) {
        const kakaoData = await axios({
          method: "get",
          url: "https://dapi.kakao.com/v2/local/geo/coord2address.json",
          headers: {
            Authorization: "KakaoAK a6ccc2bf513d01266f264d20a3cb24bb",
          },
          params: { x: this.longitude, y: this.latitude },
        });
        const { data } = kakaoData;
        const { documents } = data;
        if (documents.length > 0) {
          const { address } = documents[0];
          const {
            region_1depth_name,
            region_2depth_name,
            region_3depth_name,
          } = address;

          if (region_3depth_name) {
            this.name = region_3depth_name;
          } else if (region_2depth_name) {
            this.name = region_2depth_name;
          } else if (region_1depth_name) {
            this.name = region_1depth_name;
          }
        }
      }
    },
    ...mapActions("place", ["setLocation", "resetLocation", "setPermission"]),
  },
  computed: {
    ...mapState("place", ["permission"]),
  },
  watch: {
    isLoading: function(newValue) {
      if (newValue) {
        this.currentLocation();
      }
    },
    name: function(newValue) {
      if (newValue) {
        this.setLocation({
          latitude: this.latitude,
          longitude: this.longitude,
          name: this.name,
        });
      }
    },
  },
};
</script>

<style></style>
