<template>
  <div class="home-place-list w-full flex items-center justify-center">
    <div class="container">
      <div class="home-place-list-header w-full flex flex-col">
        <GeoLocation>
          <div class="home-place-list-header-location flex flex-row items-end">
            <p>
              <b v-if="!!location.name">{{ location.name }}</b> 추천공간
            </p>

            <div
              @click="onChangeLocationLoading"
              class="location flex flex-row items-center"
            >
              <img src="@/assets/home-location.png" alt="location" />
              <span>현재 위치</span>
            </div>
          </div>
        </GeoLocation>

        <div class="place-more flex flex-row justify-end">
          <router-link :to="{ name: 'PlaceList' }">공간 더보기 +</router-link>
        </div>
      </div>

      <div class="home-place-list-items grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-2">
        <PlaceItem v-for="item in recommends" :key="item.id" :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
import PlaceItem from "@/components/PlaceItem.vue";
import GeoLocation from "@/components/GeoLocation.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "HomePlaceList",
  components: {
    PlaceItem,
    GeoLocation,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      locationLoading: false,
    };
  },
  mounted() {
    this.onRecommendLoad();
  },
  methods: {
    onChangeLocationLoading() {
      this.locationLoading = true;
    },
    onRecommendLoad() {
      const { latitude, longitude } = this.location;
      if (latitude && longitude) {
        this.getRecommend({ 
          lat: latitude,
          lon: longitude, 
          distance: 100000000, 
          limit: 8,
          is_add_distance: 1,
          is_order_distance: 1,
          is_finish: 0,
        });
      } else {
        this.getRecommend();
      }
    },
    ...mapActions("place", ["getRecommend"]),
  },
  computed: {
    ...mapState("place", ["location", "recommends"]),
  },
  watch: {
    location: function() {
      this.onRecommendLoad();
    },
  },
};
</script>

<style scoped lang="scss">
.home-place-list {
  margin-top: -50px;
  padding-top: 130px;
  padding-bottom: 85px;
  background-color: #f5f5f5;
  overflow: hidden;

  .home-place-list-header {
    font-size: 30px;
    letter-spacing: -1.5px;
    font-weight: 300;
    color: #1a1a1a;

    b {
      font-weight: 500;
    }

    .location {
      margin-left: 20px;
      cursor: pointer;

      span {
        font-size: 20px;
        letter-spacing: -0.8px;
        color: #222;
        margin-left: 2px;
      }
    }

    .home-place-list-header-location {
      margin-bottom: 22px;
    }

    .place-more {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.16px;
      text-align: center;
      color: #808080;
      margin-bottom: 43px;
    }
  }

  .home-place-list-items {
    gap: 50px;
  }
}

@media (max-width: 639px) {
  .home-place-list {
    .home-place-list-header {
      font-size: 20px;
      .location {
        &>img {
          width: 10px;
        }
        span {
          font-size: 15px;
        }
      }
      .home-place-list-header-location {
        margin-bottom: 10px;
      }
      .place-more {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
