<template>
  <div
    v-if="reviews.length > 0"
    class="home-interior-review-list w-full flex items-center justify-center"
  >
    <div class="container">
      <h3><b>사용자 후기</b></h3>

      <DaynamicStack :data="reviews" />

      <div class="flex flex-row justify-center">
        <button @click="goToInteriorReviewList" class="review-more">
          더보기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DaynamicStack from "@/components/DaynamicStack.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "HomeInteriorReviewList",
  components: { DaynamicStack },
  computed: {
    ...mapState("review", ["reviews"]),
  },
  mounted() {
    this.getReviewList();
  },
  methods: {
    goToInteriorReviewList() {
      this.$router.push({ name: "InteriorReviewList" });
    },
    ...mapActions("review", ["getReviewList"]),
  },
};
</script>

<style scoped lang="scss">
.home-interior-review-list {
  padding-top: 65px;
  padding-bottom: 75px;

  h3 {
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 55px;

    b {
      font-weight: 500;
    }
  }

  .review-more {
    width: 307px;
    height: 64px;
    border: solid 1px #000;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.9px;
    text-align: center;
    color: #121212;
    margin-top: 75px;
  }
}

@media (max-width: 639px) {
  .home-interior-review-list {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .review-more {
      font-size: 12px;
      height: 40px;
      width: 250px;
      margin-top: 30px;
    }
  }
}
</style>
